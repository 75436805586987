/**
 * [toggle description]
 */
export default function (breakpoints) {
    $('.js-toggle-display').click(function (e) {
        e.preventDefault();

        var target = $(this).attr('data-toggle-display');
        var breakpoint = $(this).attr('data-toggle-display-breakpoint') ? $(this).attr('data-toggle-display-breakpoint') : 'md';

        if (target === undefined) {
            console.error('You are missing an element: data-toggle-display');
            console.warn('Please add the following to your HTML code: data-toggle-display');
        }

        if ($(window).outerWidth() < breakpoints[breakpoint]) {
            $(this).toggleClass('reverse');
            $(target).toggle().toggleClass('is-active');
        }
    });
}
