export default function (options) {
    let defaults = {
            timeoutInfoMessages: 0,
        },
        settings = $.extend(defaults, options);

    // Info Message fadeout
    if (settings.timeoutInfoMessages > 0) {
        setTimeout(function () {
            $('.info.message').fadeOut('fast');
        }, settings.timeoutInfoMessages);
    }

    // Article '+' and '-' buttons
    $('.qtyplus').click(function (e) {
        e.preventDefault();
        let field = $(this).parent().find('input.amount');
        let currentVal = parseInt(field.val());
        if (!isNaN(currentVal)) {
            field.val(currentVal + 1);
        } else {
            field.val(1);
        }
    });
    $(".qtyminus").click(function (e) {
        e.preventDefault();
        let field = $(this).parent().find('input.amount');
        let currentVal = parseInt(field.val());
        if (!isNaN(currentVal) && currentVal > 1) {
            field.val(currentVal - 1);
        } else {
            field.val(1);
        }
    });

    // Articles image gallery
    // Preview image
    $('.js-gallery .inner').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        arrows: false,
        draggable: false,
        dots: true,
        fade: true,
        asNavFor: '.js-gallery-nav',
        adaptiveHeight: true
    });

    // Thumbnail images
    $('.js-gallery-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 0,
        swipeToSlide: true,
        focusOnSelect: true,
        centerMode: false,
        asNavFor: '.js-gallery .inner',
        prevArrow: $('.prevArrow'),
        nextArrow: $('.nextArrow'),
        vertical: true,
        responsive: [
            {
                breakpoint: 4000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 990,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }]
    });

    // Article image enlargement
    $('[data-fancybox="gallery"]').fancybox({
        loop: true,
        afterShow: function (instance, slide) {
            $('.js-gallery').slick('slickGoTo', slide.index);
            $('.js-gallery-nav').slick('slickGoTo', slide.index);
        },
        buttons: [
            'slideShow',
            'fullScreen',
            'thumbs',
            //'share',
            //'download',
            //'zoom',
            'close'
        ]
    });
}