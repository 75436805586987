/**
 * The header function will add/remove a class
 * to the `.js-header` element, allowing you to
 * target it in css.
 *
 * @param  Object breakpoints
 */
export default function (breakpoints) {
    var scrollTop = 100;

    $(document).ready(function(){
        if ($(window).scrollTop() < scrollTop) {
            $('.js-header').removeClass('header--small');
        } else {
            $('.js-header').addClass('header--small');
        }
    });

    $(window).scroll(function () {
        if ($(window).scrollTop() < scrollTop) {
            $('.js-header').removeClass('header--small');
        } else {
            $('.js-header').addClass('header--small');
        }
    });
};
