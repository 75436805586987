export default function () {
    $('[data-set-background-image-right]').each(function (index) {
        if ($(this).find('img').length) {
            var image = $(this).find('img');
            var imageUrl = $(image).attr('src');

            $(this).find('img').hide();

            $(this).css({
                'background': 'linear-gradient(to left, rgba(255,255,255, 0) 30%, rgba(255,255,255,1) 40%), url(' + imageUrl + ') no-repeat right'
            });
        }
    });

    $('[data-set-background-image-left]').each(function (index) {
        if ($(this).find('img').length) {
            var image = $(this).find('img');
            var imageUrl = $(image).attr('src');

            $(this).find('img').hide();

            $(this).css({
                'background': 'linear-gradient(to right, rgba(255,255,255, 0) 30%, rgba(255,255,255,1) 40%), url(' + imageUrl + ') no-repeat left'
            });
        }
    });
}
