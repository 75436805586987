export default function () {
    window.googleRecaptchaCallback = function() {
        let siteKey = document.getElementsByTagName('body')[0].getAttribute('data-recaptcha-key');
        document.querySelectorAll('.invisible-recaptcha').forEach((element) => {
            grecaptcha.render(element.getAttribute('id'), {
                'sitekey': siteKey,
                'callback': function (token) {
                    element.closest('form').querySelector('.g-recaptcha-response').value = token;
                    element.closest('form').submit();
                }
            });
        });
    };

    let scriptTag = document.createElement('script');
    scriptTag.src = `https://www.google.com/recaptcha/api.js?onload=googleRecaptchaCallback&render=explicit`;
    document.getElementsByTagName('head')[0].insertBefore(scriptTag, null);
}
