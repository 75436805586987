/**
 * @param  Object breakpoints
 */
export default function (breakpoints) {
    /**
     * When clicking on the Open/Close icon,
     * this'll add and remove various classes for you to reference in your css.
     */
    $('.js-menu-toggle').click(function() {
        $(this).toggleClass('is-active');

        $('body').toggleClass('menu-is-active');

        $('.has-sub').removeClass('submenu-is-active');
        $('.js-submenu-toggle').removeClass('is-active');
    });

    /**
     * When clicking on the sub-menu icon,
     * this'll add and remove various classes for you to reference in your css.
     */
    $('.js-submenu-toggle').click(function() {
        $(this).toggleClass('is-active');

        $('.js-submenu-toggle').not($(this)).removeClass('is-active');

        $('.submenu-is-active').not($(this).parents('.has-sub')).removeClass('submenu-is-active');

        $(this).parent('.has-sub').toggleClass('submenu-is-active');
    });

    /**
     * When clicking anywhere but one of the mentioned targets,
     * this will remove the active states of all elements,
     * effectively hiding the navigation.
     */
    $(window).click(function(e) {
        var target = e.target;

        if ($('body').hasClass('menu-is-active')
            && !$(target).is('.js-menu-toggle')
            && !$(target).is('.js-submenu-toggle')
            && !$(target).is('.fa')
            && !$(target).is('nav')
            && !$(target).is('nav input')
            && !$(target).is('.is-moved')
            && !$(target).is('.is-moved *')
            && !$(target).is('nav button')) {
                removeMenuActiveState();
        }
    });

    /**
     * When clicking Esc on your keyboard this will remove the active states of all elements,
     * effectively hiding the navigation.
     */
    $(window).keyup(function(e) {
        var which = e.which;

        if ($('body').hasClass('menu-is-active') && which == 27) {
            removeMenuActiveState();
        }
    });

    /**
     * When resizing the browser to a width greater than the given value
     * this will remove the active states of all elements, effectively hiding the navigation.
     */
    $(window).resize(function () {
        if ($(window).outerWidth() > breakpoints.md) {
            removeMenuActiveState();
        }
    });

    /**
     * If the given item(s) have a UL child,
     * add a class to represent this.
     */
    $('.nav__item').has('ul').addClass('has-sub');
};

/**
 * Remove all classes, effectively hiding the navigation.
 */
function removeMenuActiveState() {
    $('body').removeClass('menu-is-active');
    $('.has-sub').removeClass('submenu-is-active');
    $('.js-menu-toggle').removeClass('is-active');
    $('.js-submenu-toggle').removeClass('is-active');
}
