export default function() {
    $('.js-basket-item-jump').click(function () {
        var toggle = $(this).attr('data-toggle'),
            jumpBy = parseInt($(this).siblings('.js-basket-item-amount').attr('data-jump-by')),
            amount = parseInt($(this).siblings('.js-basket-item-amount').val());

        if (toggle == 'plus') {
            amount = amount + jumpBy;
        } else {
            if (! amount <= 0) {
                amount = amount - jumpBy;
            }
        }

        $('.js-basket-item-amount').val(amount);

        calculateOrderTotal();
    });

    function calculateOrderTotal() {
        var orderTotal = 0;

        $('.js-basket-item-price').each(function () {
            var itemPrice = parseInt($(this).attr('data-price')),
                amount = parseInt($(this).parents('.basket__product').find('.js-basket-item-amount').val()),
                productTotalPrice = itemPrice * amount;

            orderTotal = productTotalPrice;
        });

        var formattedCurrency = OSREC.CurrencyFormatter.format(orderTotal, {
            currency: 'EUR'
        });

        $('.js-order-total').html(formattedCurrency);
    }
}
