/**
 * The merge function allows you to add elements that are
 * originally not in the `.nav` element, to be added till a certain breakpoint is met.
 *
 * @param  Object breakpoints
 */
window.mergeWidth = null;
export default function (breakpoints) {
    if (!window.mergeWidth || (window.mergeWidth !== $(window).width())) {
        if ($('nav.nav').length > 1) {
            console.error('There are too many "nav" HTML element with the "nav" class.');
            console.warn('Please only use one "nav" HTML element with the "nav" class.');
        }

        $('.js-nav-merge[data-id][data-position][data-breakpoint][data-priority][data-move]').each(function () {
            var originalPosition = $(this).data('id'),
                position = $(this).data('position'),
                breakpoint = breakpoints[$(this).data('breakpoint')],
                target = '.nav',
                priority = $(this).data('priority'),
                willMove = $(this).data('move');

            var elements = {
                id: originalPosition,
                position: position,
                breakpoint: breakpoint,
                target: target,
                priority: priority,
                move: willMove,
            };

            checkForErrorsIn(elements);

            if ($(window).outerWidth() < breakpoint) {
                if (position == 'prepend') {
                    if ($(this).is('ul') || willMove) {
                        $(this).attr('data-priority', priority)
                            .addClass('is-moved')
                            .addClass('is-prepend')
                            .addClass(originalPosition)
                            .prependTo(target);
                    } else {
                        $(this).find('> ul')
                            .attr('data-priority', priority)
                            .addClass('is-moved')
                            .addClass('is-prepend')
                            .addClass(originalPosition)
                            .prependTo(target);
                    }
                } else {
                    if ($(this).is('ul') || willMove) {
                        $(this).attr('data-priority', priority)
                            .addClass('is-moved')
                            .addClass('is-append')
                            .addClass(originalPosition)
                            .appendTo(target);
                    } else {
                        $(this).find('> ul')
                            .attr('data-priority', priority)
                            .addClass('is-moved')
                            .addClass('is-append')
                            .addClass(originalPosition)
                            .appendTo(target);
                    }
                }
            } else {
                $('.' + originalPosition).prependTo('#' + originalPosition)
                    .removeClass('is-moved')
                    .removeClass('is-append')
                    .removeClass('is-prepend')
                    .removeClass(originalPosition);
            }
        });

        $('.is-append').sort(function (a, b) {
            return (a.dataset.priority < b.dataset.priority) ? -1 : (a.dataset.priority > b.dataset.priority) ? 1 : 0;
        }).appendTo('.nav');

        $('.is-prepend').sort(function (a, b) {
            return (a.dataset.priority < b.dataset.priority) ? -1 : (a.dataset.priority > b.dataset.priority) ? 1 : 0;
        }).prependTo('.nav');

        window.mergeWidth = $(window).width();
    }
}

function checkForErrorsIn(elements) {
    for (var key in elements) {
        if (elements[key] === undefined) {
            console.error('You are missing an element: ' + key);
            console.warn('Please add the following to your HTML code: data-' + key);
        }
    }
}
