window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');

require('./vendor/jqueryUI');

require('./vendor/doubleTapToGo');
require('./vendor/fancybox');
require('slick-carousel');
require('./vendor/match-height');

require('./scripts/cookie');
require('./scripts/event');

import header from './scripts/header';
import merge from './scripts/merge';
import navigation from './scripts/navigation';
import setBackgroundImage from './scripts/setBackgroundImage';
import toggle from './scripts/toggle';
import googleReCaptcha from './scripts/googleRecaptcha';
import 'simplebar';

// Webshop includes
import checkout from './scripts/checkout';
import basket from './scripts/basket';
import articles from './scripts/articles';
import filters from './scripts/filters';

/**
 * Main Scripts
 *
 * This main scripts file is where you'll write most
 * of your (jQuery) scripts.
 */

(function ($) {

    /**
     * As soon as this file is loaded, jQuery will remove the `no-jquery`
     * class from the `body`. This indicates that jQuery has been loaded.
     */
    $('body').removeClass('no-jquery');

    /**
     * As soon as this file is loaded, jQuery will remove the `u-jquery`
     * class from the hidden `divs`. This indicates that jQuery has been loaded.
     */
    $('.u-jquery').removeClass('u-jquery');


    /**
     * Initialize Google reCaptcha
     */
    googleReCaptcha();

    /**
     * Object with breakpoint sizes defined. These are
     * based on Bootstrap's Grid.
     *
     * @type Object
     */
    var breakpoints = {
        "xs": 480,
        "sm": 768,
        "md": 992,
        "lg": 1200
    };

    // Header
    header(breakpoints);

    $(window).resize(function () {
        header(breakpoints);
    });

    // Navigation
    navigation(breakpoints);

    /**
     * Make sure touch-enabled devices can view the drop-down menus
     * starting from a landscape mode (> breakpoints.sm)
     */
    $(window).resize(function () {
        if ($(window).outerWidth() > breakpoints.sm) {
            $('.nav .nav__item:has(ul)').doubleTapToGo();
        }
    });

    // Merge
    merge(breakpoints);

    $(window).resize(function () {
        merge(breakpoints);
        matchHeightArticles();
    });

    // Toggle
    toggle(breakpoints);

    $(window).resize(function () {
        toggle(breakpoints);
    });

    // Replace `img` with `background-image` in parent `div`
    setBackgroundImage();



    // Webshop
    basket();
    checkout();
    articles({
        timeoutInfoMessages: 4000
    });
    filters({
        afterLoad: function () {
            matchHeightArticles();
        },
        afterNavigation: function () {
            $('html,body').animate({
                scrollTop: $(".cms-shop-articles").offset().top - 100
            }, 500);
        }
    });

    // MatchHeight articles e.d.
    function matchHeightArticles() {
        $('.product__item .info').matchHeight({
            byRow: true
        });
        $('.content__menu .inner .info h2').matchHeight({
            byRow: true
        });
        $('.news .item h3').matchHeight({
            byRow: true
        });
        $('.diensten-nav .match').matchHeight({
            byRow: true
        });
        $('.diensten .inner.match').matchHeight({
            byRow: true
        });
        $('.diensten h3.match').matchHeight({
            byRow: true
        });
        $('.projecten .project .info').matchHeight({
            byRow: true
        });
        $('.projecten .project').matchHeight({
            byRow: true
        });
        $('.nieuwsberichten .inner .nieuws .info').matchHeight({
            byRow: true
        });
        $('.nieuwsberichten .inner .nieuws').matchHeight({
            byRow: true
        });
        $('.match-landingpage').matchHeight({
            byRow: true
        });
        $('header .menu-dienst .inner').matchHeight({
            byRow: true
        });
    }

    matchHeightArticles();

    setTimeout(function(){
        matchHeightArticles();
    }, 1000);

    // Fancybox
    $(".fancybox, .lightbox").fancybox({
        // Options will go here
    });


    $('.tab').on('mouseenter', function(){
        $(this).animate({
            'right': '0'
        }, 'slow');
    });

    $('.tab').on('mouseleave', function(){
        $(this).stop(true).animate({
            'right': '-175px'
        }, 'slow');
    });

        // HOME SLIDE START
        // Slider Hero
        $('.js-hero-slider').slick({
            infinite: true,
            speed: 200,
            fade: true,
            cssEase: 'linear',
            waitForAnimate: true,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            pauseOnDotsHover: true,
            rows: 0,
            dots: false
        });

        // navigation for home slider
        $('.js-hero-slider-nav').slick({
            slidesToShow: 4,
            swipe: true,
            focusOnSelect: true,
            autoplay: true,
            autoplaySpeed: 4000,
            waitForAnimate: true,
            arrows: false,
            pauseOnHover: true,
            asNavFor: '.js-hero-slider',
            responsive: [
                {
                    breakpoint: 4000,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                    }
                }, {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: false,
                    }
                }, {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: false,
                    }
                }]
        });

    // onChange content slide > change nav slide current slide (index)
    $('.js-hero-slider').on('afterChange', function(event,slick,i){
        $('.js-hero-slider-nav .slick-slide').stop(true).removeClass('slick-current');
        $('.js-hero-slider-nav .slick-slide').stop(true).eq(i).addClass('slick-current');
    });

    // init current class
    $('.js-hero-slider-nav .slick-slide').eq(0).addClass('slick-current');

    // when hovering over slick nav slider > pause content slide, fetch hovered nav slide index > activate content slide with the same index
    $('.js-hero-slider-nav .slick-slide').on('mousemove', function(event,slick,i){
        $('.js-hero-slider').slick('slickPause');
        let hoveredIndex = $(this).data('slick-index');
        $('.js-hero-slider-nav .slick-slide').removeClass('slick-current');
        $('.js-hero-slider-nav .slick-slide[data-slick-index = ' + hoveredIndex + ']').addClass('slick-current');

        $('.js-hero-slider').slick('slickGoTo', hoveredIndex);
    });

    // resume autoplay of the content slider when not hovering the nav slider anymore
    $('.js-hero-slider-nav .slick-slide').on('mouseleave', function(event,slick,i) {
        $('.js-hero-slider').slick('slickPlay');
    });

    // HOME SLIDER STOP

    setTimeout(function(){
        // Categories slider
        $('.js-brandslider section').slick({
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            pauseOnHover: false,
            swipeToSlide: true,
            focusOnSelect: true,
            arrows: false,
            rows: 0,
            dots: false,
            autoplay: true,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                }]
        });
    }, 500);

    // Search toggle
    $(".header .search button").click(function (e) {
        if ($(window).outerWidth() > breakpoints.md) {
            e.preventDefault();
            $('.header .search input[type=search]').focus();
        }

        var form = $(this).parent('form');
        var status = $(this).attr('data-active');
        var textHasBeenInserted = $(this).siblings('.box').find('.keywords').val().length;

        if (status === 'false') {
            $('.header .search').toggleClass("active");
            $('.header .searchbar').toggleClass("active");

            $(this).attr('data-active', 'true');
        } else if (textHasBeenInserted === 0) {
            $('.header .search').toggleClass("active");
            $('.header .searchbar').toggleClass("active");

            $(this).attr('data-active', 'false');
        } else {
            form.submit();
        }
    });

    // Leftnav + Filters toggle
    $('.js-toggle-left-navigation').off('click').on('click', function () {
        var ToggleSubMenu = $('.left__nav');
        var ToggleMenuButton = $('.js-toggle-left-navigation');

        if (ToggleSubMenu.hasClass('active')) {
            ToggleSubMenu.removeClass('active');
            ToggleMenuButton.removeClass('reverse active');
        } else {
            ToggleSubMenu.addClass('active');
            ToggleMenuButton.addClass('reverse active');
        }
        return false;
    });

    $('.filtertogglebutton').off('click').on('click', function () {
        var ToggleFilter = $('.filter');
        var ToggleFilterButton = $('.filtertogglebutton');

        if (ToggleFilter.hasClass('active')) {
            ToggleFilter.removeClass('active');
            ToggleFilterButton.removeClass('reverse active');
        } else {
            ToggleFilter.addClass('active');
            ToggleFilterButton.addClass('reverse active');
        }
        return false;
    });

    // Smooth scroll
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);

            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 180
                }, 1000);
                return false;
            }
        }
    });

    //scroll at product detail
    $.fn.matchHeight._afterUpdate = function (event, groups) {
        if($('.webshop__article .article__scroll').length){
            // Sidebar scroll
            var articleScroll = $('.webshop__article .article__scroll');
            var articleScrollHeight = $(articleScroll).outerHeight();
            var footerFromTop = ($('.accessoiries').offset().top - 0);
            var articleScrollAbsolutePosition = ((footerFromTop - articleScrollHeight) - articleScrollHeight);

            $(articleScroll).outerWidth($(articleScroll).parents('.webshop__article .prod__info').width());

            $(window).scroll(function () {
                if ($(window).scrollTop() > articleScrollAbsolutePosition) {
                    $(articleScroll).addClass('absolute');
                } else {
                    $(articleScroll).removeClass('absolute');
                }
            });
        }
    };

    // Product Anchors
    $(".js-anchors .anchor__item .anchor__link").click(function () {
        $(this).parent().addClass('selected');
        $(this).parent().siblings().removeClass('selected');
    });


    //FAQ toggle ToggleBox
    $('.faq .open-toggle').hide();
    $('.faq .open').show();
    $('.faq .closed').hide();
    $(".faq .toggle").click(function () {
        $(this).closest('.toggle-box').find(".open-toggle").slideToggle("slow", function () {
            if ($(this).closest('.toggle-box').hasClass('active')) {
                $(this).closest('.toggle-box').removeClass('active');
                $(this).closest('.toggle-box').find('.closed').hide();
                $(this).closest('.toggle-box').find('.open').show();
            }
            else {
                $(this).closest('.toggle-box').addClass('active');
                $(this).closest('.toggle-box').find('.closed').show();
                $(this).closest('.toggle-box').find('.open').hide();
            }
        });
    });

})(jQuery);
